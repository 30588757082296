<template>
  <div>
    <div>FP&A</div>
  </div>
</template>

<script>
import NoSoftwareConnected from "../common/connect-software/NoSoftwareConnected.vue";
export default {
  components: {
    NoSoftwareConnected,
  },
  data() {
    return {
      fpa: [],
    };
  },
};
</script>

<style></style>
