<template>
  <div style="position: absolute; top: 40%; left: 35%">
    <v-row>
      <v-col cols="2">
        <img
          width="120"
          src="../../../assets/xero-buttons/no-software-connected.png"
        />
      </v-col>
      <v-col class="ml-10" align-self="center">
        <p
          class="inActiveColor--text fs-20 f-inter fw-600 ls-03"
          style="width: 350px !important"
        >
          Connect your accounting software to view reports
        </p>
        <v-btn
          @click="routeToIntegration()"
          class="elevation-0 lightPurple--text lightPurpleBg f-inter fw-600 fs-12 text-none ls-03"
          style="border-radius: 8px !important"
          >Go to Integration</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    routeToIntegration() {
      this.$router.push("/integration");
    },
  },
};
</script>

<style></style>
